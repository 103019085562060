<template>
  <div class="min-h-screen h-full bg-[#F7FBFE]">
    <TsHeaderCheckout />

    <ClientOnly>
      <TsModalBranchSelector
        v-if="rootStore.isAppHydrated"
        v-model:visible="branchStore.branchSelectorModalVisible"
        id="header-branch-selector"
        data-testid="header-branch-selector"
      />
    </ClientOnly>

    <div>
      <slot />
    </div>

    <TsFooterCheckout />
  </div>
</template>

<script lang="ts" setup>
const rootStore = useRootStore();
const branchStore = useBranchStore();
</script>
